/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-18 11:23:44
 * @Module: 编辑个人中心
 */
 <template>
  <div class="profileEdit-box">
    <div class="profileEdit-box-title">
      基本资料
    </div>
    <div class="profileEdit-box-form"
         v-loading="logding">
      <el-form ref="formData"
               label-width="100px"
               :model="formData">
        <el-form-item label="用户名">
          <el-input v-model="formData.nick_name"
                    type="text"
                    :maxlength="12"
                    show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formData.phone"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="formData.gender">
            <el-radio :label="0">女</el-radio>
            <el-radio :label="1">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="简介">
          <el-input :rows="5"
                    show-word-limit
                    :maxlength="250"
                    v-model="formData.description"
                    type="textarea"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="profileEdit-box-bottom">
      <div class="profileEdit-box-bottom-btn hover"
           @click="_req_infoUpdate">完成</div>
    </div>
  </div>
</template>
 <script>
export default {
  components: {},
  data () {
    return {
      logding: true,
      formData: {
        nick_name: '',
        description: '',
        gender: 0,
        phone: 0
      }
    };
  },
  mounted () {
    this._req_data()
  },
  methods: {
    async _req_data () {
      this.logding = true
      const { data } = await this.$api.profileEditApi();
      this.logding = false
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = data[key]
      })
      this.$store.commit('user/setUserInfo', data)
    },
    async _req_infoUpdate () {
      this.logding = true
      const {  ...params } = this.formData
      const { status, info } = await this.$api.infoUpdateApi({
        ...params,
        user_id: this.$route.query.uuid
      });
      this.logding = false
      if (status == 200) {
        this.$store.commit('user/setUserInfo', params)
        this.$router.go(0)
        this.$message.success(info)
      } else {
        this.$message.error(info)
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
.profileEdit-box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &-title {
    width: 884px;
    line-height: 50px;
    background: #ffffff;
    border-bottom: 1px solid #eee;
    font-size: 16px;
    color: #333;
    padding: 0 30px;
  }
  &-form {
    width: 567px;
    margin-top: 40px;
    min-height: 300px;
  }
  &-bottom {
    position: relative;
    width: 567px;
    height: 300px;
    &-btn {
      width: 90px;
      line-height: 34px;
      background: #df2a29;
      border-radius: 2px;
      text-align: center;
      color: #fff;
      position: absolute;
      right: 0px;
    }
  }
}
</style>